import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PrivacyPolicyModal from './PrivacyPolicyModal';
const TermsAndConditionsModal = ({ show, handleClose }) => {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const handlePrivacyPolicyOpen = () => setShowPrivacyPolicy(true);
    const handlePrivacyPolicyClose = () => setShowPrivacyPolicy(false);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>SMS Disclosure</h3>
        <p>
          By opting in, you agree to receive the following types of messages:
        </p>
        <ul>
          <li>Weekly updates about our services and promotions</li>
          <li>Special announcements and limited-time offers</li>
          <li>Important service notifications</li>
        </ul>
        <p>Message frequency may vary depending on your engagement.</p>
        <p>
          <strong>Message and data rates may apply</strong>. Check with your
          mobile service provider for details.
        </p>
        <h3>Opt-In and Opt-Out Instructions</h3>
        <p>
          To opt-in, check the opt-in box provided on the contact form. By
          opting in, you consent to receive SMS messages at the phone number
          provided.
        </p>
        <p>
          To opt out, reply <strong>STOP</strong> to any SMS message received.
          You can also text <strong>HELP</strong> for assistance.
        </p>
        <h3>Privacy Policy</h3>
        <p>
          Your personal data is handled in accordance with our{" "}

          <button onClick={handlePrivacyPolicyOpen} className="btn btn-link p-0 text-decoration-none text-green">
              Privacy Policy 
          </button>
          .
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
      <PrivacyPolicyModal show={showPrivacyPolicy} handleClose={handlePrivacyPolicyClose} />
    </Modal>
    
  );
};

export default TermsAndConditionsModal;
